import gsap from 'gsap';
import videojs from 'video.js';

import Stone, { gettext as _ } from "stonejs";

import MobileDetect from 'mobile-detect';
import $ from 'jquery';

var langue = "fr";

var w_video;
var h_video;


var md = new MobileDetect(window.navigator.userAgent);




var player = videojs('playervideo', {
    controls: true,
    autoplay: false,
    preload: 'auto',
    playlines: true,
    // fluid: true,
    // responsive: true,
    userActions: {
        doubleClick: false
    },
    controlBar: {
        'liveDisplay': true,
        'pictureInPictureToggle': false,
        'fullscreenToggle': true,
        'progressControl': {
            seekBar: true
        }
    },

});


var catalogs = {
    "fr": {
        "messages": {
            "psw": ["SAISISSEZ LA CLE A 4 CHIFFRES QUI VOUS A ÉTÉ REMISE"],
            "ouvrir": ["Ouvrez"],
            "relancez": ["Revoir le film"],
            "commentaire": ["COMMENTAIRES OU QUESTIONS"],
            "mentions": ["© Mentions légales 2021. Tout droits résérvés."],
            "nom": ["Nom"],
            "pays": ["Pays"],
            "company": ["Société"],
            "email": ["Email"],
            "message": ["Message"],
            "envoyer": ["Envoyer"],
            "merci": ["Merci pour votre message. Comptez sur notre réponse. Bien sincèrement."],
            "rgpd": ["Gérard Bertrand s'engage à protéger et à respecter votre vie privée. Nous n'utiliserons vos données personnelles que pour administrer votre compte et vous fournir les produits et services demandés. Nous aimerions vous contacter ponctuellement au sujet de nos produits et services, ainsi que d'autres contenus susceptibles de vous intéresser. Si vous consentez à ce que nous vous contactions à cette fin, veuillez cocher la case."]
        }
    },
    "en": {
        "messages": {
            "psw": ["ENTER THE 4 DIGITS KEY YOU’VE BEEN SENT"],
            "ouvrir": ["Open"],
            "relancez": ["Replay"],
            "commentaire": ["COMMENTS OR QUESTIONS"],
            "mentions": ["© Copyright 2021. All rights reserved."],
            "nom": ["Name"],
            "pays": ["Country"],
            "company": ["Company"],
            "email": ["Email"],
            "message": ["Message"],
            "envoyer": ["Send"],
            "merci": ["Thank you for your message. We will revert to you shortly. Sincerely yours."],
            "rgpd": ["Gérard Bertrand aims to respect you privacy and we will only use your personal data to manage your account. We will send no more than four times a month communication emails about our wines and products."]
        }
    },
    "zh": {
        "messages": {
            "psw": ["输入你得到的4位数字代码"],
            "ouvrir": ["开放"],
            "relancez": ["重播"],
            "commentaire": ["评论或问题"],
            "mentions": ["© 法律声明 2021。保留所有权利。"],
            "nom": ["名称"],
            "pays": ["国家"],
            "company": ["公司"],
            "email": ["电子邮件"],
            "message": ["您的消息"],
            "envoyer": ["发送"],
            "merci": ["Thank you for your message. We will revert to you shortly. Sincerely yours."],
            "rgpd": ["Gérard Bertrand aims to respect you privacy and we will only use your personal data to manage your account. We will send no more than four times a month communication emails about our wines and products."]

        }
    }
};


// TRAD
window._ = Stone.gettext; // Alias the Stone.gettext function
Stone.addCatalogs(catalogs); // Add catalogs (here it is hard coded for the need of the example
Stone.enableDomScan(true); // Allow Stone.js to scan the DOM to find translatable nodes
Stone.setLocale("fr"); // Sets the locale to french


var step = 0;

var scene = [{
        fr: {
            titrestep: "",
            titrebt1: "",
            urlvideo1: "https://d1n92womdl8bu6.cloudfront.net/fr/CLOS-DU-TEMPLE-LANCEMENT-FR_AC.mp4",
            titrebt2: "",
            urlvideo2: ""
        },
        en: {
            titrestep: "",
            titrebt1: "",
            urlvideo1: "https://d1n92womdl8bu6.cloudfront.net/fr/CLOS-DU-TEMPLE-LANCEMENT-EN_AC.mp4",
            titrebt2: "",
            urlvideo2: ""
        },
        zh: {
            titrestep: "",
            titrebt1: "",
            urlvideo1: "https://d1n92womdl8bu6.cloudfront.net/fr/CLOS-DU-TEMPLE-LANCEMENT-ZH_AC.mp4",
            titrebt2: "",
            urlvideo2: ""
        }
    },
    {
        fr: {
            titrestep: "Merci pour votre participation",
            titrebt1: "Recommencez la visite",
            urlvideo1: "https://d1n92womdl8bu6.cloudfront.net/fr/M1-FR.mp4",
            titrebt2: "Posez vos questions à Gérard Bertrand",
            urlvideo2: "https://d1n92womdl8bu6.cloudfront.net/fr/M1-FR.mp4"
        },
        en: {
            titrestep: "Merci pour votre participation EN",
            titrebt1: "Le mot de la fin EN",
            urlvideo1: "https://d1n92womdl8bu6.cloudfront.net/fr/M1-EN.mp4",
            titrebt2: "Posez vos questions à Gérard Bertrand EN",
            urlvideo2: "https://d1n92womdl8bu6.cloudfront.net/fr/M1-EN.mp4"
        },
        zh: {
            titrestep: "Merci pour votre participation EN",
            titrebt1: "Le mot de la fin EN",
            urlvideo1: "https://d1n92womdl8bu6.cloudfront.net/fr/M1-EN.mp4",
            titrebt2: "Posez vos questions à Gérard Bertrand EN",
            urlvideo2: "https://d1n92womdl8bu6.cloudfront.net/fr/M1-EN.mp4"
        }

    }

]







// Init
gsap.to('#baseline, #zonehead, #languedoc, #logoclos, #playervideo, #page-intro, .page-secret, #page-main, #contactform', 0, { opacity: 0 });

gsap.to('#contactform, .page-secret, #page-main', 0, { display: 'none' });

// gsap.to('#baseline, #zonehead, #languedoc, #logoclos, #playervideo, #page-intro, .page-secret, #page-main', 0, { opacity: 0 });

// gsap.to('.page-secret, #page-main', 0, { display: 'none' });


// Start
gsap.to('#baseline', 1, { opacity: 1 });
gsap.to('#zonehead', 1.5, { opacity: 1, delay: .3 });
gsap.to('#languedoc', 1.5, { opacity: 1, delay: .6 });
gsap.to('#logoclos', 1.5, { opacity: 1, delay: .9 });
gsap.to('#page-intro', 2, { opacity: 1, delay: 1.5 });

// gsap.to('#page-main', 2, { opacity: 1, delay: 1.5 });
// gsap.to('.page-langue', 1, { y: -5, opacity: 1, delay: 1.3 });
// gsap.to('.page-intro', 1, { opacity: 1, delay: 1.3 });


window.govideo = function(num) {

    sizevideo();

    if (step === scene.length) {


        if (num == 1) { // video1
            step = 0;
            loadScene(step);
        } else { // video2
            step = 0;
            window.location = "https://www.gerard-bertrand.com/";
        };


    } else {

        if (num == 1) { // video1
            player.src(scene[step][langue].urlvideo1);
            player.poster('https://d1n92womdl8bu6.cloudfront.net/fr/poster.jpg');
        } else { // video2
            player.src(scene[step][langue].urlvideo2);
            player.poster('https://d1n92womdl8bu6.cloudfront.net/fr/poster.jpg');
        };

        player.requestFullscreen();
        player.play();

        // gsap.to('#bt1, #bt2', 1, { opacity: 0 });
        gsap.to('#titrestep', 0, { display: 'none' });
        // gsap.to('#bt1, #bt2', 0, { display: 'none', delay: 1 });


    }





};


player.on('ended', function() {
    gsap.to('#playervideo', 1, { opacity: 0, display: 'none' });
    gsap.to('#page-main, #baseline, #zonehead, #languedoc, #logoclos', 1, { opacity: 1, display: 'block', delay: 1 });

    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
    }

    // step++;
    // loadScene(step);
});




window.loadScene = function(step) {
    console.log('loadscene', step);

    // gsap.to('.page-main', 1, { display: 'flex', opacity: 1, delay: 1 });
    // bt1.innerHTML = '<span>' + scene[step][langue].titrebt1 + '</span>';
    // bt2.innerHTML = '<span>' + scene[step][langue].titrebt2 + '</span>';
    // titrestep.innerHTML = scene[step][langue].titrestep;

    player.src(scene[step].urlvideo1);

    if (scene[step][langue].titrestep === '') {
        govideo(1);
    } else {

        if (scene[step][langue].titrebt2 === '') {
            // gsap.to('#bt2, #titrestep', 0, { display: 'none' });
            // gsap.to('#bt1', 1, { display: 'inline-block', opacity: 1 });
        } else {

            // gsap.to('#bt1, #bt2, #titrestep', 0, { display: 'inline-block' });
            // gsap.to('#bt1, #bt2, #titrestep', 1, { opacity: 1 });
        }

    }




    // gsap.to('#bt1, #bt2', 0, { display: 'inline-block' });

};

window.sizevideo = function() {
    var w = window.innerWidth;
    // console.log(w);

    // console.log(md.is('iPhone'));



    switch (true) {
        case (w >= 1000):
            player.dimensions(1100, 618);
            break;
        case (w >= 600 && w < 1000):

            if (md.is('iPhone')) {
                player.dimensions(500, 280);
            } else {
                player.dimensions(600, 337);
            };




            break;
        case (w > 375 && w < 600):
            player.dimensions(350, 198);
            break;
        case (w <= 375):
            player.dimensions(350, 198);
            break;

        default:
            console.log(w);
            player.dimensions(200, 112);
    }



}

window.addEventListener('resize', sizevideo);

window.addEventListener("orientationchange", function() {
    if (window.matchMedia("(orientation: portrait)").matches) {
        console.log("LANSCAPE" + window.innerHeight);
        // w_video = 400;
        // h_video = 225;
        // gsap.to('#playervideo', 1, { width: w_video, height: h_video, scaleX: 1, scaleY: 1, opacity: 1 });


    }
    if (window.matchMedia("(orientation: landscape)").matches) {
        console.log("PORTRAIT" + window.innerHeight)
            // w_video = 800;
            // h_video = 450;
            // gsap.to('#playervideo', 1, { width: w_video, height: h_video, scaleX: 1, scaleY: 1, opacity: 1 });
    }
}, false);


window.login = function() {
    if (psw.value == '1618') {
        gsap.to('#playervideo', 1, { opacity: 1, delay: 1 });
        gsap.to('.page-secret, #baseline, #zonehead, #languedoc, #logoclos, #page-main', 1, { display: 'none' });
        loadScene(step);
    } else {
        console.log('nok');
    };
};

window.changelangue = function(lan) {
    // gsap.to('.page-secret', 1, { display: 'block', opacity: 1, delay: 1 });
    gsap.to('.page-langue, #page-intro', 1, { opacity: 0, height: 0, display: 'none' });
    langue = lan;
    Stone.setLocale(lan);

    gsap.to('#playervideo', 1, { opacity: 1, delay: 1 });
    gsap.to('.page-secret, #baseline, #zonehead, #languedoc, #logoclos, #page-main', 1, { display: 'none' });
    loadScene(step);
};

window.relance = function() {
    gsap.to('#playervideo', 1, { opacity: 1, display: 'block', delay: 1 });
    gsap.to('.page-secret, #baseline, #zonehead, #languedoc, #logoclos, #page-main, #contactform', 1, { display: 'none' });
    loadScene(0);
};

window.viewform = function() {
    gsap.to('#contactform', 1, { opacity: 1, display: 'block' });
};


var contactform = document.getElementById("contactform");
// … et prenez en charge l'événement submit.
contactform.addEventListener("submit", function(event) {
    event.preventDefault();

    var myData = { nom: nom.value, pays: pays.value, company: company.value, email: email.value, message: message.value, rgpd: rgpd.value };

    fetch('https://www.consultant-multimedia.com/client/closdutemple/scont.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'https://www.clos-du-temple.com',
                'Access-Control-Allow-Credentials': true
            },
            body: JSON.stringify({
                // your expected POST request payload goes here
                nom: nom.value,
                pays: pays.value,
                company: company.value,
                email: email.value,
                message: message.value,
                rgpd: rgpd.value
            })
        })
        .then(res => res.json())
        .then(data => {
            // enter you logic when the fetch is successful
            var merci = _("merci");
            alert(merci)
        })
        .catch(error => {
            // enter your logic for when there is an error (ex. error toast)
            console.log(error)
        })


});


sizevideo();